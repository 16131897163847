import packageInfo from '@valk-nx/package.json';

/* istanbul ignore next */
export const environment = {
	production: false,
	sitedata: 'https://localhost:4000/content-bff/v1/sitedata/',
	version: packageInfo?.version || 'test',
};

export const storeVersion = '1';
