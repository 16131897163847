import { InjectionToken } from '@angular/core';

export type Config = {
	apiUrl: string;
	apiCookies: {
		withCredentials: boolean;
	};
	assetsRoot: string;
	bookingtool: string;
	environment: string;
	gtmCode: string;
	host: string;
	hotelSlug: string;
	name: string;
	origin: string;
	pollingTime: number;
	recaptchaKey: string;
	storyblokAccesstoken: string;
	storyblokEndpoint: string;
	theme: string;
};

export const APP_CONFIG: InjectionToken<Config> = new InjectionToken<Config>(
	'Application Config',
);
