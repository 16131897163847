import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {
	APP_ID,
	APP_INITIALIZER,
	importProvidersFrom,
	NgModule,
} from '@angular/core';
import {
	BrowserModule,
	provideClientHydration,
} from '@angular/platform-browser';

import { ApmModule } from '@elastic/apm-rum-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService,
} from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';

import {
	ASSETS_ROOT,
	createTranslateLoader,
	fallbackLanguage,
} from '@valk-nx/core/lib/core';
import { RouterEffects } from '@valk-nx/router-store/router.effects';
import { GoogleTagManagerService } from '@valk-nx/services/google-tag-manager/src/lib/google-tag-manager.service';
import { SESSION_STORAGE } from '@valk-nx/storyblok/components/order-giftcard/src/lib/order-giftcard';
import { StoryblokSharedModule } from '@valk-nx/storyblok-core/src/lib/components';
import { AppRoutingModule } from '@valk-nx/storyblok-route/src/lib/routing/app.routing.module';
import { TagManagerEffects } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.effects';

import { ConfigModule } from './config/config.module';
import { AppComponent } from './app.component';
import { ReducerProvider, reducerToken } from './root.reducer';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');

export function initializeGTM(gtmInitService: GoogleTagManagerService) {
	return (): void => gtmInitService.init();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		ApmModule,
		AppRoutingModule,
		BrowserModule,
		ConfigModule,
		CommonModule,
		EffectsModule.forRoot([RouterEffects, TagManagerEffects]),
		StoreModule.forRoot(reducerToken, {
			runtimeChecks: {
				strictActionImmutability: true,
				strictStateImmutability: true,
			},
		}),
		StoryblokSharedModule,
		TranslateModule.forRoot({
			defaultLanguage: fallbackLanguage,
			loader: {
				deps: [HttpClient, ASSETS_ROOT],
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
			},
		}),
	],
	providers: [
		importProvidersFrom(
			AngularSvgIconModule.forRoot(),
			MarkdownModule.forRoot(),
		),
		provideClientHydration(),
		provideHttpClient(withFetch()),
		ReducerProvider,
		TranslateService,
		{
			provide: APP_ID,
			useValue: 'valkContentApp',
		},
		{
			provide: SESSION_STORAGE,
			useValue: globalThis.sessionStorage,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeGTM,
			deps: [GoogleTagManagerService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
